uw_utils.ready(function() {
  // TODO: allow breakpoint to be configured

  // assumes only one instance of header search and .uw_main_nav
  function add_searchform_to_access() {

    var uw_search_list_items = document.querySelectorAll(".uw-nav-menu > ul li.uw-search-list-item"),
        uw_main_ul = document.querySelectorAll(".uw-nav-menu:not(.uw-nav-menu-secondary) > ul")[0],
        uw_search_form = document.querySelectorAll("header .uw-search-form")[0];

    if( window.matchMedia('(max-width: 31.2em)').matches && uw_search_list_items.length < 1 && uw_search_form ) {

      var uw_search_li_el = document.createElement('li'),
          uw_main_ul_first_child = uw_main_ul.firstElementChild;

      uw_search_li_el.classList.add('page_item');
      uw_search_li_el.classList.add('uw-search-list-item');
      uw_search_li_el.appendChild(uw_search_form);
      uw_main_ul.insertBefore(uw_search_li_el, uw_main_ul_first_child);
    } else if ( window.matchMedia('(min-width: 31.25em)').matches && uw_search_list_items.length > 0 ) {
      var uw_search_form_in_nav = document.querySelectorAll(".uw-nav-menu .uw-search-form")[0],
          uw_header_search = document.querySelectorAll(".uw-header-search")[0],
          uw_search_list_item = uw_search_list_items[0];

      if ( !uw_search_form_in_nav )
        return false;

      uw_header_search.appendChild(uw_search_form_in_nav);
      uw_search_list_item.parentNode.removeChild(uw_search_list_item);
    }
  }

  function mediaQueriesSupported() {
      return (typeof window.matchMedia != "undefined" || typeof window.msMatchMedia != "undefined");
  }

  //On resize and load, if screen is less than breakpoint, move search form into nav bar or vice versa
  var uw_main_menu_ul = document.querySelectorAll(".uw-main-nav > nav > ul");
  if( uw_main_menu_ul.length && mediaQueriesSupported() ){
    add_searchform_to_access();

    window.addEventListener('resize', function() {
      var uw_search_form_in_nav = document.querySelectorAll(".uw-nav-menu .uw-search-form")[0];
      add_searchform_to_access();
    }, false);
  }

});
