uw_utils.ready(function() {

  // side navigation
  var uw_breakpoint = 640;
  var side_nav_menu = document.querySelectorAll(".uw-side-nav")[0];
  var side_nav_button = document.querySelectorAll(".uw-side-nav-button")[0];
  var side_nav_menu_hidden_class = "uw-side-nav-is-hidden";
  var side_nav_menu_open_class = "uw-side-menu-open";
  var overlay = document.querySelectorAll(".uw-overlay")[0];

  window.uwShowSideNavMenu = function(){
    overlay.classList.remove("uw-is-active");
    side_nav_menu.parentNode.classList.remove(side_nav_menu_hidden_class);
  }
  window.uwHideSideNavMenu = function(){
    if (!side_nav_menu.parentNode.classList.contains(side_nav_menu_open_class)) {
      side_nav_menu.parentNode.classList.add(side_nav_menu_hidden_class);
    } else {
      overlay.classList.add("uw-is-active");
    }
  }

  var sideNavMenuToggle = function(){
    if (window.innerWidth > uw_breakpoint) {
      uwShowSideNavMenu();
    } else {
      uwHideSideNavMenu();
    }
  }


  if (side_nav_menu) {
    sideNavMenuToggle();
    window.addEventListener("resize", sideNavMenuToggle);
  }
  

  var toggle_side_nav_menu = function(){
    var el = side_nav_menu.parentNode;
    el.classList.toggle(side_nav_menu_hidden_class);
    el.classList.toggle(side_nav_menu_open_class);
    if (el.classList.contains(side_nav_menu_hidden_class)) {
      overlay.classList.remove("uw-is-active");
    } else {
      overlay.classList.add("uw-is-active");
      bindOverlayEvents();
    }
  }

  if (side_nav_button)
    side_nav_button.addEventListener("click", toggle_side_nav_menu);

  // close sub page menu when clicking overlay
  var body_for_overlay = document.querySelectorAll("body .uw-overlay.uw-is-active")[0];

  var clearMenuOverlay = function(){
    toggle_side_nav_menu();
  };

  var bindOverlayEvents = function(){
    var body_for_overlay = document.querySelectorAll("body .uw-overlay.uw-is-active")[0];
    body_for_overlay.addEventListener("click", clearMenuOverlay);
    body_for_overlay.addEventListener("touchend", clearMenuOverlay);
  };

  if (body_for_overlay) {
    bindOverlayEvents();
  }

});